import axios from 'axios'
const server = process.env.VUE_APP_API_URL || ''
const api = `${server}/api/registration/`

const loadRegistrations = () => {
  return axios.get(api)
}

const approveRegistration = (data) => {
  return axios.post(api + 'approve', data, { withCredentials: true, credentials: 'include' })
}

const rejectRegistration = (registrationId) => {
  return axios.post(api + 'reject', { registrationId }, { withCredentials: true, credentials: 'include' })
}

export default {
  loadRegistrations,
  approveRegistration,
  rejectRegistration,
}
