<template>
  <div>
    <h1 class="display-1 font-weight-bold mx-1 mb-3">
      Review Registrations.
    </h1>
    <p>
      This page shows current registration requests
    </p>
        <v-card class="mb-4" width="100%" v-for="registration of registrations" :key="registration.id">
            <v-card-title
              class="
                title
                font-weight-bold
              "
            >Request from {{registration.customerName}}</v-card-title>
            <v-container>
            <v-row >
                <v-col cols="6">
                  <v-card class="mx-auto">
                    <v-list>
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon>mdi-account</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title v-text="`${registration.firstName} ${registration.lastName}`"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon>mdi-home</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <!-- <v-list-item-title v-text="registration.customerName"></v-list-item-title> -->
                          <v-list-item-title>
                            {{registration.customerName}}<br />
                            {{registration.addressLine1}}<br />
                            {{registration.addressLine2}}<br />
                            {{registration.addressLine3}}<br />
                            {{registration.addressLine4}}<br />
                            {{registration.city}}<br />
                            {{registration.region}}<br />
                            {{registration.postalCode}}<br />
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon>mdi-email</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title v-text="registration.email"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon>mdi-phone</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title v-text="registration.phoneNumber"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-col>
                <v-col cols="6">
                  <match-contact :registration="registration" :contactNames="xeroContactNames" :contacts="xeroContacts" @approve="approveRegistration" @reject="rejectRegistration"></match-contact>
                </v-col>
              </v-row>
            </v-container>
        </v-card>
  </div>
</template>

<script>
import RegistrationApi from '@/api/registration'
import AccountingApi from '@/api/accounting'
import MatchContact from './matchContact.vue'

export default {
  props: {
  },
  components: {
    MatchContact
  },
  data () {
    return {
      xeroContacts: [],
      xeroContactNames: [],
      registrations: [],
      users: [],
      loadingData: true,
      headers: [
        {
          text: 'Approve',
          value: 'id'
        },
        {
          text: 'Name',
          value: 'customerName'
        },
        {
          text: 'Contact',
          value: 'contact'
        },
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'Status',
          value: 'status'
        }

      ]
    }
  },
  async created () {
    await AccountingApi.getContacts().then(response => {
      this.xeroContactNames = response.data.map(item => item.name)
      this.xeroContacts = response.data
    })
    RegistrationApi.loadRegistrations().then(response => {
      this.registrations = response.data.filter(item => item.status === 'PENDING')
      this.loadingData = false
    }).catch(err => console.log('woops', err))
  },
  computed: {
  },
  watch: {},
  methods: {
    async approveRegistration (data) {
      this.loadingData = true
      console.log(data)
      RegistrationApi.approveRegistration(data).then(response => {
        RegistrationApi.loadRegistrations().then(response => {
          this.registrations = response.data
          this.loadingData = false
        }).catch(err => console.log('woops', err))
      }).catch(err => console.log('woops', err))
    },
    async rejectRegistration (contact) {
      this.loadingData = true
      RegistrationApi.rejectRegistration(contact.id).then(response => {
        RegistrationApi.loadRegistrations().then(response => {
          this.registrations = response.data
          this.loadingData = false
        }).catch(err => console.log('woops', err))
      }).catch(err => console.log('woops', err))
    }
  }
}
</script>
